'use strict';

/* libs */
import svg4everybody from 'svg4everybody';
import A11yDialog from 'a11y-dialog';
import Rellax from 'rellax';
import Scrollbar from 'smooth-scrollbar';

/* custom imports */
import NativeConsole from './modules/native-console';
import ScrollClass from './modules/scroll-class';

var video;

/**
 * Chilli App
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */
class ChilliApp{

	/**
	 * @constructor
	 */
	constructor(){
		new NativeConsole();

		/* external svg polyfill, https://github.com/jonathantneal/svg4everybody */
		svg4everybody();

		if(document.getElementById('my-accessible-dialog')){

			var dialogEl = document.getElementById('my-accessible-dialog');
			var mainEl = document.querySelector('.content');
			var dialog = new A11yDialog(dialogEl, mainEl);


			if( Cookies.get('popup' + dialogEl.getAttribute('data-id')) != '1' ){
				dialog.show();
				Cookies.set('popup'+ dialogEl.getAttribute('data-id'), '1', { expires: 2 });
			}
		}

		// fetch video
		video = document.querySelector('.js-video');

		checkFooter();
		window.onscroll = function() {
			checkFooter();
		};

		function checkFooter() {
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight / 2) {
				document.querySelector('.footer').classList.add('up');
			}else {
				document.querySelector('.footer').classList.remove('up');
			}
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
				document.querySelector('.footer').classList.add('animate');
			} else {
				document.querySelector('.footer').classList.remove('animate');
			}
		}

		// Attach listener function on state changes
		window.matchMedia('(max-width: 700px)').addListener(this.switchSources);

		// initial width check
		this.switchSources();

		new Rellax('.rellax', {});

		const elementsToAnim  = [
			{
				element: document.querySelector('.body-text ul'),
				classesToToggle: ['list-animation'],
				once: false,
				enabled: true,
				events: false,
				threshold: 100
			}
		];

		// document.querySelector('.body-text ul').addEventListener('insideViewport', function(){
		// 	console.log(`I see you! ${this}`);
		// });
		//
		// document.querySelector('.body-text ul').addEventListener('outsideViewport', function(){
		// 	console.log(`I can't see you. ${this}`);
		// });

		new ScrollClass({
			elements: elementsToAnim,
			frequency: 300
		});

	}

	switchSources() {
		const sources = video.getElementsByTagName('source');
		if (window.matchMedia('(max-width: 700px)').matches) { // If media query matches
			video.src = sources[1].src;
		} else {
			video.src = sources[0].src;
		}
		video.play();
		// console.log(video.parent);
		video.classList.add('fadeIn');
	}

}


/**
 * js vanilla equivalent to jquerys $(document).ready -- fires when DOM is ready (assets not yet ready)
 */
window.addEventListener('load', () => {

	new ChilliApp();
});
